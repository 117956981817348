import { useState, useEffect } from "react";
import { cn } from "../../../util/cn";
import "../../../styles/sliderWithCheckbox.css";
import SliderCentered from "./SliderCentered";

interface SliderWithCheckboxProps {
  option: any;
  onSelectionChange: any;
  disabled?: boolean;
  value: number;
  isChecked: boolean;
  isViewOnly?: boolean;
  readOnly?: boolean;
}

const SliderWithCheckbox = ({
  option,
  onSelectionChange,
  disabled,
  value,
  isChecked = false,
  isViewOnly = false,
  readOnly = false,
}: SliderWithCheckboxProps) => {
  const [isCheckedValue, setIsCheckedValue] = useState(false);
  const [sliderValue, setSliderValue] = useState(50);

  useEffect(() => {
    setIsCheckedValue(isChecked);
  }, [isChecked]);

  useEffect(() => {
    setSliderValue(value);
  }, [value]);

  const handleCheckboxChange = (e: any) => {
    setIsCheckedValue(e.target.checked);
    if (e.target.checked) {
      // If checked, add the slider value to the form
      onSelectionChange(sliderValue);
    } else {
      onSelectionChange(sliderValue, true);
    }
  };

  const handleSliderChange = (val: any) => {
    const value = parseInt(val[0], 10);
    setSliderValue(value);
    if (isCheckedValue) {
      // Update value in the form if checkbox is checked
      onSelectionChange(value);
    }
  };

  return (
    <div>
      <div key={option.id} className="flex  xs:w-[70vw] items-center my-4 gap-x-[25px]">
        {!isViewOnly && (
          <input
            type="checkbox"
            checked={isCheckedValue}
            onChange={handleCheckboxChange}
            className={cn(
              "h-5 w-5 shadow text-instant-teams-blue-L1 leading-8 bg-white rounded border-instant-teams-blue-Main outline-none focus:ring-1",
              { 
                "cursor-pointer": !isViewOnly && !disabled,
              },
              {
                "cursor-not-allowed": readOnly || (!isViewOnly && disabled),
              },
            )}
            disabled={readOnly || disabled}
          />
        )}

        <div className="flex-grow">
          <SliderCentered
            value={!isCheckedValue ? 50 : sliderValue}
            onChange={handleSliderChange}
            className="w-full h-2 mt-2 cursor-pointer"
            disabled={disabled || !isCheckedValue}
            handleCenterDot={true}
            isViewOnly={isViewOnly}
            readOnly={readOnly}
          />

          <div className="flex justify-between mt-2">
            <span className="w-full text-left text-[12px] pr-[20px]">
              {option.labelLeft}
            </span>
            <span className="w-full text-right text-[12px] pl-[20px]">
              {option.labelRight}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SliderWithCheckbox;
