import React, { useState, useEffect, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../../../providers/auth";
import GreenUnderlineHeading from "../../../../../components/custom-ui/headings/heading";
import DialogWrapper from "../../../../../components/custom-ui/dialog/Dialog";
import useModal from "../../../../../hooks/useModal";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import DisplaySkillNames from "../../../../../components/custom-ui/DisplaySkill/DisplaySkillNames";
import { getCannedSkills, getTACTagsSkills } from "../../../../../data/getSkills";
import { updateSkillByUser, fetchSkillsByUserId } from "../../../../../data/user";
import toast from "react-hot-toast";
import ToastAlert from "../../../../../components/custom-ui/toast-alert/ToastAlert";



function SeekerRegistrationStepFive({
    onDone,
    seekerInfo,
}: {
    onDone: Function;
    seekerInfo: any;
}) {
    const auth = useAuth();
    const navigate = useNavigate();
    const { user } = auth;
    const [pending, setPending] = useState(false);
    //const [skills, setSkills] = useState(["QA Automation", "Database Tester", "Manual Testing"]);
    const [skillSuggestionList, setSkillSuggestionList] = useState([]);
    const [skillInput, setSkillInput] = useState("");
   
    console.log(seekerInfo,"From Fith step")
    const handleSkipSignup = () => {
        localStorage.removeItem("seekerMail")
        if (auth.user) {
            navigate("/jobs");
        }
    }

    const [userInputSkillValue, setUserInputSkillValue] = useState("");

    const [tacSkillSuggestionList, setTacSkillSuggestionList] = useState([]);
    const [filteredSuggestions, setFilteredSuggestions] = useState([]);
    const [skills, setSkills] = useState([]);
    const [tacVerifiedSkills, setTacVerifiedSkills] = useState([]);
    const [tacTags, setTacTags] = useState([]);
    const { isShowing: showSkillManageDialog, toggle: toggleSkillManageDialog } = useModal();
    const [activeSkillList, setActiveSkillList] = useState("skills");
    const [loading, setLoading] = useState(false);

    const handleInputChange = (e: any) => {
        const value = e.target.value;
        const suggestionList = activeSkillList === "tacTags" ? tacSkillSuggestionList : skillSuggestionList;
        if (value.includes(',')) {
            const skill = value.split(',')[0].trim();
            if (skill && !suggestionList.includes(skill)) {
                addSkill(skill);
            }
            setUserInputSkillValue("");
        } else {
            setUserInputSkillValue(value);
            if (value) {
                const filtered = suggestionList.filter((skill: string) => {
                    const lowerCaseSkill = skill.toLowerCase();
                    const lowerCaseValue = value.toLowerCase();
                    return lowerCaseSkill.split(' ').some((word: string) => word.startsWith(lowerCaseValue)) || lowerCaseSkill == lowerCaseValue;
                });
                setFilteredSuggestions(filtered);
            } else {
                setFilteredSuggestions([]);
            }
        }
    };


    const handleSuggestionClick = (suggestion: string) => {
        const currentSkills = getCurrentSkills();
        const setCurrentSkills = getCurrentSkillsSetter();

        if (currentSkills.length >= 5) {
            toast.error("You can only add up to 5 skills.");
            setUserInputSkillValue(""); setFilteredSuggestions("")
            return;
        }

        if (!currentSkills.some((skill: any) => skill.skill === suggestion)) {
            setCurrentSkills([...currentSkills, { skill: suggestion, customSkill: false }]);
        }
        setUserInputSkillValue("");
        setFilteredSuggestions([]);
    };

    const removeSkill = (skillToRemove: any) => {
        const currentSkills = getCurrentSkills();
        const setCurrentSkills = getCurrentSkillsSetter();

        setCurrentSkills(currentSkills.filter((skill: any) => skill.skill !== skillToRemove.skill));
    };

    const addSkill = (skill: string) => {
        const currentSkills = getCurrentSkills();
        const setCurrentSkills = getCurrentSkillsSetter();
        if (currentSkills.length >= 5) {
            toast.error("You can only add up to 5 skills.");
           
            setUserInputSkillValue(""); setFilteredSuggestions("")
            return;
        }

        if (skill && !currentSkills.some((s: any) => s.skill === skill)) {
            setCurrentSkills([...currentSkills, { skill: skill, customSkill: true }]);
        }
    };

    const getCurrentSkills = () => {
        switch (activeSkillList) {
            case "skills":
                return skills;
            case "tacVerifiedSkills":
                return tacVerifiedSkills;
            case "tacTags":
                return tacTags;
            default:
                return [];
        }
    };

    const getCurrentSkillsSetter = () => {
        switch (activeSkillList) {
            case "skills":
                return setSkills;
            case "tacVerifiedSkills":
                return setTacVerifiedSkills;
            case "tacTags":
                return setTacTags;
            default:
                return () => { };
        }
    };

    useEffect(() => {
        const fetchSkills = async () => {
            const skillData = await getCannedSkills();
            setSkillSuggestionList(skillData?.map((data: any) => data?.skill_name) ?? []);
        };
        fetchSkills();
    }, []);

    useEffect(() => {
        const fetchSkills = async () => {
            const skillData = await getTACTagsSkills();
            setTacSkillSuggestionList(skillData?.map((data: any) => data?.skill_name) ?? []);
        };
        fetchSkills();
    }, []);

    useEffect(() => {
        const userSkillList = async () => {
            const skillList = await fetchSkillsByUserId(user.id);
            if (skillList) {
                setSkills(skillList?.seeker_tagged_skills ?? []);
                setTacVerifiedSkills(skillList?.tac_verified_skills ?? []);
                setTacTags(skillList?.tac_tags ?? [])
            }
        };
        userSkillList();
    }, []);

    const saveSkills = async () => {
        setLoading(true);
        const data = {
            Skills: {
                seeker_tagged_skills: skills,
                tac_verified_skills: tacVerifiedSkills,
                tac_tags: tacTags
            },
            name:seekerInfo?.email
        };
        try {
            await updateSkillByUser(user.id, data);
            setLoading(false);
            toggleSkillManageDialog();
            navigate("/jobs");
        } catch (error) {
            setLoading(false);
        }
    };


    return (
        <div className="flex justify-center w-full p-4 mt-12"> {/* Added margin from top */}
            <>
                <div className="bg-white max-w-xl p-6 xs:w-full xs:p-0"> {/* Updated width and added padding */}
                    <h2 className="text-2xl font-bold text-center text-[#15415E] mb-4">Add Skills <span className="text-sm text-[#15415E]">(Optional)</span></h2>
    
                    <div className="mb-4">
                        <label className="block text-[#15415E] text-sm font-medium mb-1">Please select up to 5 skills</label>
                        <div className="flex items-center mt-1">
                            <input
                                type="text"
                                placeholder="Type Skill"
                                value={userInputSkillValue}
                                onChange={handleInputChange}
                                className="peer mt-1 block w-full inp py-2 rounded-none sm:text-sm text-sm font-medium"
                                style={{ boxShadow: 'none' }}
                            />
                            
                            {userInputSkillValue && (
                                <div className="flex items-center justify-center w-7 h-7 ml-2 bg-gray-100 rounded-full cursor-pointer"
                                    onClick={() => { setUserInputSkillValue(""); setFilteredSuggestions("") }}
                                >
                                    <FontAwesomeIcon icon={faTimes} color="#565656" />
                                </div>
                            )}
                        </div>
                        
                    </div>
    
                    {filteredSuggestions.length > 0 && (
                        <div className="bg-white border border-gray-300 w-full left-0 mt-1 max-h-40  overflow-y-auto rounded-md shadow-lg z-50">
                            {filteredSuggestions?.map((suggestion: any, index: number) => (
                                <div
                                    key={index}
                                    className="p-2 hover:bg-gray-200 cursor-pointer"
                                    onClick={() => handleSuggestionClick(suggestion)}
                                >
                                    {suggestion}
                                </div>
                            ))}
                        </div>
                    )}
    
                    <div className="flex flex-wrap gap-2 mb-4">
                        {getCurrentSkills()?.map((data: any, index: number) => (
                            <Fragment key={index} className="flex items-center bg-gray-100 rounded-full px-4 py-2 text-sm text-[#15415E]">
                                <DisplaySkillNames
                                    skillname={data.skill}
                                    displayCloseIcon={true}
                                    customSkill={data.customSkill}
                                    removeSkill={() => removeSkill(data)}
                                />
                            </Fragment>
                        ))}
                    </div>
    
                    <div className="flex justify-center gap-4 mt-16 xs:flex-wrap">
                        <button
                            onClick={saveSkills}
                            className="py-2 px-8 text-white font-medium rounded-2xl mb-5 focus:outline-none focus:ring-2"
                            style={{ backgroundColor: '#15415E', width: '200px' }}
                            disabled={pending}
                        >
                            {pending ? (
                                <div className="flex items-center justify-center space-x-2">
                                    <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"></path>
                                    </svg>
                                </div>
                            ) : (
                                "Save & Continue"
                            )}
                        </button>
    
                        <button
                            type="submit"
                            className="py-2 px-8 text-white font-medium rounded-2xl mb-5 focus:outline-none focus:ring-2"
                            style={{ backgroundColor: '#15415E', width: '200px' }}
                            onClick={() => handleSkipSignup()}
                            disabled={pending}
                        >
                            {pending ? (
                                <div className="flex items-center justify-center space-x-2">
                                    <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"></path>
                                    </svg>
                                </div>
                            ) : (
                                "Skip & Proceed"
                            )}
                        </button>
                    </div>
                </div>
            </>
            <ToastAlert />
        </div>
    );
    
};

export default SeekerRegistrationStepFive;
