import React, { useState } from "react";

import { useAuth } from "../../../providers/auth";
import AccountSettings from "./AccountSettings";
import WorkPreferences from "./WorkPreferences";
import MilitarySettings from "./MilitarySettings";
import SkillsSettings from "./SkillsSettings";

const SeekerSettingsGeneralNew = (props:any) => {
    const auth = useAuth();
    const { user } = auth;
    const [activeTab, setActiveTab] = useState("account");
    

  const renderContent = () => {
    switch(activeTab) {
      case "account":
        return <AccountSettings onStatus={props.onStatus}/>;
      case "skills":
        return (
          // <div className="bg-white rounded-xl shadow p-6">
            <SkillsSettings />
          // </div>
        );
      case "military":
        return (
          <div className="bg-white xs:w-[82vw] md:w-[82vw] rounded-xl xs:ml-[-18px] md:ml-[-18px] xs:mt-[-30px] shadow p-6">
            <MilitarySettings />
          </div>
        );
      case "preferences":
        return (
          <div className="bg-white xs:w-[82vw] md:w-[82vw] rounded-xl xs:ml-[-18px] md:ml-[-18px] xs:mt-[-30px] shadow p-6">
            <WorkPreferences />
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="flex xs:flex-col h-screen xs:h-full md:h-full bg-gray-50">
      {/* Sidebar */}
      <aside className="w-1/6 mt-12 xs:mt-2 md:mt-2 ml-6 bg-white xs:w-[90%] md:w-[90%] h-4/6 xs:h-auto md:h-auto rounded-xl shadow p-6">
  <nav>
    <ul className="space-y-4 xs:mt-2 mt-6">
      <li>
        <button
          className={`w-full text-left p-2 ${
            activeTab === "account"
              ? "text-[#15415E] font-semibold border-l-4 border-[#15415E]"
              : "text-[#15415E]"
          }`}
          onClick={() => setActiveTab("account")}
        >
          Account Settings
        </button>
      </li>
      <li>
        <button
          className={`w-full text-left p-2 ${
            activeTab === "skills"
              ? "text-[#15415E] font-semibold border-l-4 border-[#15415E]"
              : "text-[#15415E]"
          }`}
          onClick={() => setActiveTab("skills")}
        >
          Skills
        </button>
      </li>
      <li>
        <button
          className={`w-full text-left p-2 ${
            activeTab === "military"
              ? "text-[#15415E] font-semibold border-l-4 border-[#15415E]"
              : "text-[#15415E]"
          }`}
          onClick={() => setActiveTab("military")}
        >
        Military Affiliation
        </button>
      </li>
      <li>
        <button
          className={`w-full text-left p-2 ${
            activeTab === "preferences"
              ? "text-[#15415E] font-semibold border-l-4 border-[#15415E]"
              : "text-[#15415E]"
          }`}
          onClick={() => setActiveTab("preferences")}
        >
          Work Preferences
        </button>
      </li>
    </ul>
  </nav>
     </aside>


      {/* Main Content */}
      <main className="w-4/5 p-6 mt-6 mx-4">
        {renderContent()}
      </main>
    </div>
  );
};

export default SeekerSettingsGeneralNew;
