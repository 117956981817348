import { useEffect, useState } from "react";
import { gql, useQuery } from "@apollo/client";
import { wordpressClient } from "../../external/wordpress/wordpressClient";
import Meta from "../../components/custom-ui/meta/Meta";
import { useAuth } from "../../providers/auth";
import { Tab } from "@headlessui/react";
import GeneralInfoPostDisplay_NewUI from "../../components/general-info/GeneralInfoPostaDisplay_NewUI";

function GeneralInfoPage_NewUI() {
    const [nodes, setNodes] = useState();
    const usertype = useAuth()?.user?.claims?.type;
    const parent = usertype === "A" ? 6 : usertype === "E" ? 7 : 5;
    const parentInt = Number.isInteger(parent)
        ? parent
        : Math.floor(parseFloat(parent));

    const getNodes = gql`
    query nodeQuery($parent: Int!) {
      categories(where: { parent: $parent }) {
        edges {
          node {
            id
            name
            posts {
              edges {
                node {
                  id
                }
              }
            }
          }
        }
      }
    }
  `;

    const { loading, error, data } = useQuery(getNodes, {
        variables: { parent: parentInt },
        client: wordpressClient,
        fetchPolicy: "cache-and-network",
    });

    useEffect(() => {
        setNodes(data?.categories?.edges);
    }, [data]);

    return (
        <div className="h-screen">
            {nodes?.length > 0 ? (
                <div className="">
                    <Meta title="Frequently Asked Questions" />
                    <div className="flex mt-10 justify-center    w-full  items-center">
                        <div className=" w-[55%] xs:w-full md:w-full xs:ml-10 md:ml-3 xs:flex md:flex xs:justify-start  md:justify-start    w- pb-6 text-black mt-40">
                            <h1 className="text-2xl font-bold text-instant-teams-blue-Main">Frequently Asked Questions</h1>
                        </div>
                    </div>

                    <Tab.Group>
                        <div  className="flex mx-auto justify-center xs:justify-start h-3/4 w-4/5 xs:w-full md:w-full ">
                            <Tab.Panels className="basis-4/4 lg:basis-3/4 xl:basis-3/4 2xl:basis-3/4  h-full no-scrollbar w-full xs:w-[90vw] md:w-[90vw] overflow-scroll ">
                                {nodes &&
                                    nodes?.length > 0 &&
                                    nodes.map(({ node }) => {
                                        if (node?.posts?.edges && node?.posts?.edges?.length > 0) {
                                            return (
                                                <Tab.Panel
                                                    key={node?.id}
                                                    className="flex   flex-col pl-10 h-auto overflow-visible"
                                                >
                                                    <GeneralInfoPostDisplay_NewUI
                                                        posts={node?.posts?.edges}
                                                        key={node?.id}
                                                    />
                                                </Tab.Panel>
                                            );
                                        }
                                        return <Tab.Panel>No Data</Tab.Panel>;
                                    })}
                            </Tab.Panels>
                        </div>
                    </Tab.Group>

                </div>) : <div className='w-full mt-28 flex h-full justify-center items-center'>
                <img src='/images/loader_v2.gif ' alt="loader" />
            </div>}

        </div>
    );
}

export default GeneralInfoPage_NewUI;
