import React, { useState, useEffect } from 'react';
import { requireAuth } from '../../../../../providers/auth';
import CompaniesList from './CompaniesListCard/CompaniesList';
import JobsPagination from '../Components/JobsPagination/JobsPagination';
import CompanyDetails from './CompaniesDetails/CompaniesDetail';
import { useGlobalState } from '../../../../../providers/globalState/GlobalStateProvider';
import { filterCompany } from '../Components/util';
import { useAllActiveEmployersNewUi } from '../../../../../data/employer';
import Meta from '../../../../custom-ui/meta/Meta';

const Companies_newUI = () => {
    const empData = useAllActiveEmployersNewUi();
    const [selectedCompanyId, setSelectedCompanyId] = useState(null);
    const { globalState } = useGlobalState();
    const [employerData, setEmployerData] = useState([]);
    const [filteredJobs, setFilteredJobs] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [jobsPerPage, setJobsPerPage] = useState(10);
    const [empDetailData, setEmpDetailData] = useState(null);

    useEffect(() => {
        if (empData?.data) {
            setEmployerData(empData.data);
            setFilteredJobs(empData.data);
        }
    }, [empData?.data]);

    useEffect(() => {

        const filtered = filterCompany(employerData, globalState?.searchKeyWords);
        setFilteredJobs(filtered);
        setCurrentPage(1);
    }, [employerData, globalState?.searchKeyWords]);

    useEffect(() => {
        if (!selectedCompanyId) {
            if (filteredJobs.length > 0) {
                setEmpDetailData(filteredJobs[0]);
                setSelectedCompanyId(filteredJobs[0]?.id)
            } else {
                setEmpDetailData(null);
            }
        }

    }, [filteredJobs]);

    const indexOfLastJob = currentPage * jobsPerPage;
    const indexOfFirstJob = indexOfLastJob - jobsPerPage;
    const currentJobs = filteredJobs.slice(indexOfFirstJob, indexOfLastJob);
    const totalPages = Math.ceil(filteredJobs.length / jobsPerPage);

    const paginate = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleResultsPerPageChange = (e) => {
        setJobsPerPage(Number(e.target.value));
        setCurrentPage(1); // Reset to the first page when changing results per page
    };

    return (
        <div className="w-full">
            <Meta title="Companies" />
            {employerData.length > 0 ? (<div>
                <div className="grid table-fixed mt-36 pt-4 xs:grid-cols-1 grid-cols-3">
                    <div className="bg-gray-100 px-7 py-4 col-span-1 flex flex-col">
                        <div className="w-full bg-gray-100 relative">
                            <div className="text-sm mb-2 text-gray-600">{filteredJobs.length} Companies</div>
                        </div>
                        <div
                            style={{
                                scrollbarWidth: 'thin',
                                scrollbarColor: '#b3b3b3 #f0f0f0',
                            }}
                            className="flex-grow overflow-y-auto"
                        >
                            <div className='w-full mt-2' style={{ height: 'calc(100vh - 300px)' }}>
                                <div className='w-full bg-gray-100'>
                                    {currentJobs.length > 0 ? (
                                        currentJobs.map((data) => (
                                            <CompaniesList
                                                key={data.id}
                                                empData={data}
                                                setEmpDetailData={setEmpDetailData}
                                                setSelectedCompanyId={setSelectedCompanyId}
                                                selectedCompanyId={selectedCompanyId}
                                            />
                                        ))
                                    ) : (
                                        <p className="text-center text-gray-500 mt-4">No results found</p>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="flex-shrink-0">
                            <JobsPagination
                                handleResultsPerPageChange={handleResultsPerPageChange}
                                paginate={paginate}
                                currentPage={currentPage}
                                jobsPerPage={jobsPerPage}
                                totalPages={totalPages}
                            />
                        </div>
                    </div>

                    <div className="col-span-2 xs:hidden md:hidden h-[80vh] pr-4 pb-4 bg-gray-100">
                        <div style={{ height: 'calc(100vh - 200px)' }} className='w-full h-full'>
                            <CompanyDetails companyDetailData={empDetailData} />
                        </div>
                    </div>
                </div>
            </div>) : <div className='w-full mt-80 flex h-full justify-center items-center'>
                <img src='/images/loader_v2.gif ' alt="loader" />
            </div>}
        </div>
    );
};

export default requireAuth(Companies_newUI);
