import React from 'react';

const JobsPagination = (props) => {
    const {
        currentPage,
        handleResultsPerPageChange,
        paginate,
        jobsPerPage,
        totalPages
    } = props;

    const generatePageNumbers = () => {
        const pages = [];
        if (totalPages <= 5) {
            for (let i = 1; i <= totalPages; i++) {
                pages.push(i);
            }
        } else {
            pages.push(1, 2); // Always show the first two pages
            if (currentPage > 3) {
                pages.push('...');
            }
            const startPage = Math.max(3, currentPage);
            const endPage = Math.min(currentPage + 1, totalPages);
            for (let i = startPage; i <= endPage; i++) {
                pages.push(i);
            }
            if (currentPage + 1 < totalPages - 1) {
                pages.push('...');
            }
            if (currentPage + 1 < totalPages) {
                pages.push(totalPages);
            }
        }
        return pages;
    };

    return (
        <div>
            <div className="mb-1 mt-4 flex justify-between">
                <div className="mt-[-10px] flex items-center">
                    <label htmlFor="resultsPerPage" className="mr-1 text-gray-600">
                        Results per Page:
                    </label>
                    <select
                        id="resultsPerPage"
                        value={jobsPerPage}
                        onChange={handleResultsPerPageChange}
                        className="text-sm bg-gray-100 text-gray-600 focus:outline-none focus:ring-0 w-14 shadow-none"
                    >
                        <option value={10}>10</option>
                        <option value={25}>25</option>
                        <option value={50}>50</option>
                    </select>
                </div>
                <nav>
                    <ul className="inline-flex items-center">
                        {/* Previous Button */}
                        <li>
                            <button
                                onClick={() => paginate(currentPage - 1)}
                                disabled={currentPage === 1}
                                className={`text-gray-600 ${currentPage === 1 ? 'cursor-not-allowed' : ''}`}
                            >
                                &lt;
                            </button>
                        </li>
                        {/* Page Numbers */}
                        {generatePageNumbers().map((page, index) => (
                            <li key={index}>
                                {page === '...' ? (
                                    <span className="px-2 text-gray-400">...</span>
                                ) : (
                                    <button
                                        onClick={() => paginate(page)}
                                        className={`px-2 ${
                                            currentPage === page
                                                ? 'text-black font-bold'
                                                : 'text-gray-400'
                                        }`}
                                    >
                                        {page}
                                    </button>
                                )}
                            </li>
                        ))}
                        {/* Next Button */}
                        <li>
                            <button
                                onClick={() => paginate(currentPage + 1)}
                                disabled={currentPage === totalPages}
                                className={`text-gray-600 rounded-r-md ${
                                    currentPage === totalPages ? 'cursor-not-allowed' : ''
                                }`}
                            >
                                &gt;
                            </button>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    );
};

export default JobsPagination;
