import React, { useState, useEffect, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../providers/auth";
import GreenUnderlineHeading from "../../../components/custom-ui/headings/heading";
import DialogWrapper from "../../../components/custom-ui/dialog/Dialog";
import useModal from "../../../hooks/useModal";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import DisplaySkillNames from "../../../components/custom-ui/DisplaySkill/DisplaySkillNames";
import { getCannedSkills, getTACTagsSkills } from "../../../data/getSkills";
import { updateSkillByUser, fetchSkillsByUserId } from "../../../data/user";
import toast from "react-hot-toast";
import ToastAlert from "../../../components/custom-ui/toast-alert/ToastAlert";



function SkillsSettings() {
    const auth = useAuth();
    const navigate = useNavigate();
    const { user } = auth;
    const [pending, setPending] = useState(false);
    const [isModalSkills, setIsModalSkills] = useState(false);
    //const [skills, setSkills] = useState(["QA Automation", "Database Tester", "Manual Testing"]);
    const [skillSuggestionList, setSkillSuggestionList] = useState([]);
    const [skillInput, setSkillInput] = useState("");


    const closeModal = () => {

        setIsModalSkills(false);
    };


    const handleSkipSignup = () => {
        if (auth.user) {
            navigate("/jobs");
        }
    }

    const [userInputSkillValue, setUserInputSkillValue] = useState("");

    const [tacSkillSuggestionList, setTacSkillSuggestionList] = useState([]);
    const [filteredSuggestions, setFilteredSuggestions] = useState([]);
    const [skills, setSkills] = useState([]);
    const [tacVerifiedSkills, setTacVerifiedSkills] = useState([]);
    const [tacTags, setTacTags] = useState([]);
    const { isShowing: showSkillManageDialog, toggle: toggleSkillManageDialog } = useModal();
    const [activeSkillList, setActiveSkillList] = useState("skills");
    const [loading, setLoading] = useState(false);

    const handleInputChange = (e: any) => {
        const value = e.target.value;
        const suggestionList = activeSkillList === "tacTags" ? tacSkillSuggestionList : skillSuggestionList;
        if (value.includes(',')) {
            const skill = value.split(',')[0].trim();
            if (skill && !suggestionList.includes(skill)) {
                addSkill(skill);
            }
            setUserInputSkillValue("");
        } else {
            setUserInputSkillValue(value);
            if (value) {
                const filtered = suggestionList.filter((skill: string) => {
                    const lowerCaseSkill = skill.toLowerCase();
                    const lowerCaseValue = value.toLowerCase();
                    return lowerCaseSkill.split(' ').some((word: string) => word.startsWith(lowerCaseValue)) || lowerCaseSkill == lowerCaseValue;
                });
                setFilteredSuggestions(filtered);
            } else {
                setFilteredSuggestions([]);
            }
        }
    };


    const handleSuggestionClick = (suggestion: string) => {
        const currentSkills = getCurrentSkills();
        const setCurrentSkills = getCurrentSkillsSetter();

        if (!currentSkills.some((skill: any) => skill.skill === suggestion)) {
            setCurrentSkills([...currentSkills, { skill: suggestion, customSkill: false }]);
        }
        setUserInputSkillValue("");
        setFilteredSuggestions([]);
    };

    const removeSkill = (skillToRemove: any) => {
        const currentSkills = getCurrentSkills();
        const setCurrentSkills = getCurrentSkillsSetter();

        setCurrentSkills(currentSkills.filter((skill: any) => skill.skill !== skillToRemove.skill));
    };

    const addSkill = (skill: string) => {
        const currentSkills = getCurrentSkills();
        const setCurrentSkills = getCurrentSkillsSetter();
        // if (currentSkills.length >= 5) {
        //     toast.error("You can only add up to 5 skills.");

        //     setUserInputSkillValue(""); setFilteredSuggestions("")
        //     return;
        // }

        if (skill && !currentSkills.some((s: any) => s.skill === skill)) {
            setCurrentSkills([...currentSkills, { skill: skill, customSkill: true }]);
        }
    };

    const getCurrentSkills = () => {
        switch (activeSkillList) {
            case "skills":
                return skills;
            case "tacVerifiedSkills":
                return tacVerifiedSkills;
            case "tacTags":
                return tacTags;
            default:
                return [];
        }
    };

    const getCurrentSkillsSetter = () => {
        switch (activeSkillList) {
            case "skills":
                return setSkills;
            case "tacVerifiedSkills":
                return setTacVerifiedSkills;
            case "tacTags":
                return setTacTags;
            default:
                return () => { };
        }
    };

    useEffect(() => {
        const fetchSkills = async () => {
            const skillData = await getCannedSkills();
            setSkillSuggestionList(skillData?.map((data: any) => data?.skill_name) ?? []);
        };
        fetchSkills();
    }, []);

    useEffect(() => {
        const fetchSkills = async () => {
            const skillData = await getTACTagsSkills();
            setTacSkillSuggestionList(skillData?.map((data: any) => data?.skill_name) ?? []);
        };
        fetchSkills();
    }, []);

    useEffect(() => {
        const userSkillList = async () => {
            const skillList = await fetchSkillsByUserId(user.id);
            if (skillList) {
                setSkills(skillList?.seeker_tagged_skills ?? []);
                setTacVerifiedSkills(skillList?.tac_verified_skills ?? []);
                setTacTags(skillList?.tac_tags ?? [])
            }
        };
        userSkillList();
    }, []);

    const saveSkills = async () => {
        setLoading(true);
        const data = {
            Skills: {
                seeker_tagged_skills: skills,
                tac_verified_skills: tacVerifiedSkills,
                tac_tags: tacTags
            },
        };
        try {
            await updateSkillByUser(user.id, data);
            setLoading(false);
            toggleSkillManageDialog();
            setIsModalSkills(false);
        } catch (error) {
            setLoading(false);
        }
    };


    return (
        <> {/* Added margin from top */}
             <div className="bg-white rounded-xl xs:w-[82vw] md:w-[82vw] xs:mt-[-30px] md:mt-[-30px] xs:ml-[-15px] shadow p-6 mb-6">
            <div className="flex justify-between items-center">
                {/* Skills content */}
                <h3 className="text-lg font-bold text-[#15415E]">Tagged Skills</h3>
                <button className="bg-[#15415E] text-white px-6 py-2 rounded-full flex text-sm items-center w-34 h-10" onClick={() => setIsModalSkills(true)}>
                    Edit
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="2"
                        stroke="currentColor"
                        className="w-4 h-4 ml-1"
                    >
                        <path strokeLinecap="round" strokeLinejoin="round" d="M15.232 5.232l3.536 3.536m-2.036-7.036a2.5 2.5 0 113.536 3.536L7.5 19.5H4v-3.536l11.732-11.732z" />
                    </svg>
                </button>
            </div>

            <div className="mb-5">
                {getCurrentSkills()?.map((data: any, index: number) => (
                    <Fragment key={index} className="flex items-center bg-gray-100 rounded-full px-4 py-2 text-sm text-[#15415E]">
                        <DisplaySkillNames
                            skillname={data.skill}
                            displayCloseIcon={false}
                            customSkill={data.customSkill}
                            removeSkill={() => removeSkill(data)}
                        />
                    </Fragment>
                ))}
            </div>
            </div>



            <div className="bg-white rounded-xl xs:w-[82vw] md:[82vw] xs:ml-[-15px] shadow p-6">

            <div>
                <h3 className="text-lg font-bold text-[#15415E]">TAC Verified Skills</h3>
                {tacVerifiedSkills?.sort((a: any, b: any) => a.skill.localeCompare(b.skill))?.map((data: any, index: number) => (
                    <Fragment key={index}>
                        <DisplaySkillNames
                            skillname={data.skill}
                            displayCloseIcon={false}
                            customSkill={data.customSkill}
                            removeSkill={() => removeSkill(data)}
                        />
                    </Fragment>
                ))}
            </div>
           
            </div>
         
            {isModalSkills && (
                <>
                    <div className="fixed inset-0 flex items-center justify-center z-50">
                        <div className="fixed inset-0 bg-black opacity-50" onClick={closeModal}></div>
                        <div
                            className={`relative transform transition-transform duration-300 ease-in-out bg-white xs:w-[95vw] md:w-[93vw] rounded-2xl w-4/12 shadow-lg p-8`}

                        >
                                  <button
      className="absolute top-4 right-4 bg-white p-1 rounded-full"
      onClick={() => closeModal()}
    >
      <img src="/images/closeIcon.png" alt="Close Icon" className="w-8 h-8" />
    </button>
                            
                            <div className="bg-white max-w-xl p-6"> {/* Updated width and added padding */}
                                {/* <h2 className="text-2xl font-bold text-center text-[#15415E] mb-4">Add Skills <span className="text-sm text-[#15415E]">(Optional)</span></h2>
     */}
                                <div className="flex mb-5">

                                    <p className="border-b w-full py-2 font-bold text-xl text-[#15415E]">Edit Skills</p>
                                </div>

                                <div className="mb-4">
                                    <label className="block text-[#15415E] text-sm font-medium mb-1">Please select skills</label>
                                    <div className="flex items-center mt-1">
                                        <input
                                            type="text"
                                            placeholder="Type Skill"
                                            value={userInputSkillValue}
                                            onChange={handleInputChange}
                                            className="peer mt-1 block w-full inp py-2 rounded-none sm:text-sm text-sm font-medium"
                                            style={{ boxShadow: 'none' }}
                                        />
                                       

                                        {userInputSkillValue && (
                                            <div className="flex items-center justify-center w-7 h-7 ml-2 bg-gray-100 rounded-full cursor-pointer"
                                                onClick={() => { setUserInputSkillValue(""); setFilteredSuggestions("") }}
                                            >
                                                <FontAwesomeIcon icon={faTimes} color="#565656" />
                                            </div>
                                        )}
                                    </div>
                                    <label className="block text-[#15415E] text-sm font-medium mb-1">(Type Skill followed by comma)</label>

                                </div>

                                {filteredSuggestions.length > 0 && (
                                    <div className="bg-white border border-gray-300 w-full left-0 mt-1 max-h-40  overflow-y-auto rounded-md shadow-lg z-50">
                                        {filteredSuggestions?.map((suggestion: any, index: number) => (
                                            <div
                                                key={index}
                                                className="p-2 hover:bg-gray-200 cursor-pointer"
                                                onClick={() => handleSuggestionClick(suggestion)}
                                            >
                                                {suggestion}
                                            </div>
                                        ))}
                                    </div>
                                )}

                                <div className="flex flex-wrap gap-2 mb-4">
                                    {getCurrentSkills()?.map((data: any, index: number) => (
                                        <Fragment key={index} className="flex items-center bg-gray-100 rounded-full px-4 py-2 text-sm text-[#15415E]">
                                            <DisplaySkillNames
                                                skillname={data.skill}
                                                displayCloseIcon={true}
                                                customSkill={data.customSkill}
                                                removeSkill={() => removeSkill(data)}
                                            />
                                        </Fragment>
                                    ))}
                                </div>



                                <div className="flex justify-around space-x-4 mt-10 mb-4 mx-5">

                                    <button
                                        className="py-2 bg-white border border-instant-teams-blue-Main hover:border-instant-teams-blue-D1 hover:text-white hover:bg-instant-teams-blue-D1 text-[#15415E] w-full h-12 rounded-full"
                                        onClick={() => closeModal()}
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        onClick={saveSkills}
                                        className="py-2 px-8 text-white font-medium rounded-full mb-5 focus:outline-none focus:ring-2 w-full h-12"
                                        style={{ backgroundColor: '#15415E', }}
                                        disabled={pending}
                                    >
                                        {pending ? (
                                            <div className="flex items-center justify-center space-x-2">
                                                <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"></path>
                                                </svg>
                                            </div>
                                        ) : (
                                            "Save"
                                        )}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                </>
            )}
            <ToastAlert />
        </>
    );

};

export default SkillsSettings;
