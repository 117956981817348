import { useEffect, useState } from "react";
import {
  useAllMessagesByChatId,
  sendMessage,
  handleTypingMessage,
  handleDeleteMessage,
} from "../../data/messages";
import { DisplayDate } from "../../util/timestamps/timestamp";
import { useUser } from "../../providers/database";
import { useUsersByRole } from "../../data/user";
import DialogWrapper from "../custom-ui/dialog/Dialog_NewUI";
import useModal from "../../hooks/useModal";
import AddALink from "./AddALink";
import AddAnImage from "./AddAnImage";
import AddAFile_NewUI from "./AddAFile_NewUI";
import { useAuth } from "../../providers/auth";
import ClipSVG from "../custom-ui/icons/ClipSVG";

const MessageSend_NewUI = ({ chat }) => {
  const { user } = useAuth();
  const { isShowing: isShowingLink, toggle: toggleLink } = useModal();
  const { isShowing: isShowingImage, toggle: toggleImage } = useModal();
  const { isShowing: isShowingFile, toggle: toggleFile } = useModal();
  const [allMessages, setAllMessages] = useState([]);
  const [messagesByDay, setMessagesByDay] = useState([]);
  const [docId, setDocId] = useState("");
  const [newMessageStarted, setNewMessageStarted] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [canSend, setCanSend] = useState(false);
  const [linkData, setLinkData] = useState(null);
  const [imageData, setImageData] = useState(null);
  const [fileNameData, setFileNameData] = useState(null);
  const [fileData, setFileData] = useState(null);
  const [messageText, setMessageText] = useState("");
  const [unreadIds, setUnreadIds] = useState([]);
  const { id: chatId, recipientId } = chat;
  const { data } = useAllMessagesByChatId(chat?.objectID);
  const senderId = localStorage.getItem("__user_id")?.replace(/"/g, "");
  const { data: userData } = useUser(senderId);
  const { data: recipientData } = useUser(recipientId);
  const { data: usersByRole } = useUsersByRole(chat?.group);

  const handleSendClick = async () => {
    if (
      messageText.trim() ||
      linkData !== null ||
      imageData !== null ||
      fileData !== null
    ) {
      if (chat?.group === "oneOnOne") {
        const unreadUserIds = chat?.users?.filter((id) => id !== senderId);
        sendMessage(
          senderId,
          docId,
          chatId,
          messageText,
          linkData,
          imageData,
          fileData,
          unreadUserIds,
          recipientData?.email,
        );
        setLinkData(null);
        setImageData(null);
        setFileData(null);
        setFileNameData(null);
        setMessageText("");
        setDocId("");
      } else if (userData?.claims?.type === "A") {
        const unreadUserIds = unreadIds;
        sendMessage(
          senderId,
          docId,
          chatId,
          messageText,
          linkData,
          imageData,
          fileData,
          unreadUserIds,
          recipientData?.email,
        );
        setLinkData(null);
        setImageData(null);
        setFileData(null);
        setFileNameData(null);
        setMessageText("");
        setDocId("");
      } else if (userData?.claims?.type !== "A") {
        const unreadUserIds = usersByRole?.map((item) => item.id);
        sendMessage(
          senderId,
          docId,
          chatId,
          messageText,
          linkData,
          imageData,
          fileData,
          unreadUserIds,
          recipientData?.email,
        );
        setLinkData(null);
        setImageData(null);
        setFileData(null);
        setFileNameData(null);
        setMessageText("");
        setDocId("");
      }
      setNewMessageStarted(false);
    }
  };

  const handleCleanup = () => {
    if (messageText === "" && docId) {
      handleDeleteMessage(chatId, docId).then(() => {
        setDocId("");
        setNewMessageStarted(false);
      });
    }
  };

  const onKeyDownHandler = (event) => {
    if (event.keyCode === 13) {
      handleSendClick();
    }
  };

  const handleMessageChange = async (e) => {
    setMessageText(e.target.value);
    setNewMessageStarted(true);
    if (!newMessageStarted) {
      const messageId = await handleTypingMessage(chatId, senderId);
      setDocId(messageId);
    }
  };

  const handleNewMessageStart = async () => {
    setNewMessageStarted(true);
    if (!newMessageStarted) {
      const messageId = await handleTypingMessage(chatId, senderId);
      setDocId(messageId);
    }
  };

  useEffect(() => {
    const userIds = usersByRole
      ?.map((item) => item.id)
      .filter((id) => id !== userData?.id);
    if (
      userIds?.length > 0 &&
      recipientId !== null &&
      recipientId !== undefined
    ) {
      setUnreadIds([...userIds, recipientId]);
    }
  }, [usersByRole, data, recipientId, userData?.id]);

  useEffect(() => {
    const hasTypingMessage = allMessages?.some((message) => message?.isTyping);
    setIsTyping(hasTypingMessage);
  }, [allMessages]);

  useEffect(() => {
    handleCleanup();
  }, [messageText]);

  useEffect(() => {
    setAllMessages(data);
  }, [chatId, data]);

  useEffect(() => {
    // Group messages by day
    const groups = allMessages?.reduce((groups, message) => {
      const date = DisplayDate(message?.createdAt);
      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].push(message);
      return groups;
    }, {});

    // Convert groups object to array of objects
    const groupsArray = groups
      ? Object.entries(groups).map(([date, messages]) => ({
          date,
          messages,
        }))
      : [];

    setMessagesByDay(groupsArray);
  }, [allMessages]);

  useEffect(() => {
    if (messagesByDay?.length > 0) {
      const lastMessage = document.getElementById("chat-feed").lastChild;
      lastMessage.scrollIntoView(false);
    }
  }, [messagesByDay]);

  useEffect(() => {
    if (linkData !== null) {
      toggleLink();
      handleSendClick();
    }
  }, [linkData]);

  useEffect(() => {
    if (imageData !== null) {
      handleSendClick();
    }
  }, [imageData]);

  useEffect(() => {
    if (fileData !== null) {
      handleSendClick();
    }
  }, [fileData]);

  return (
    <div className="flex xs:flex-col md:flex-col space-y-4  xs:mb-[-6%] items-center p-4">
      <div className="w-full mx-2">
        <input
          type="text"
          value={messageText}
          placeholder="Write Here"
          className="w-full rounded-[60px] relative bg-[#F4F7F8] shadow-none h-[63px] pl-[30px] pr-[50px]"
          onChange={(e) => handleMessageChange(e)}
          onKeyDown={(e) => onKeyDownHandler(e)}
        />
        <div className="absolute inset-y-0 xs:mr-[-60%] xs:mt-[-18%]  right-[230px] flex items-center cursor-pointer">
          <div>
            <button
              id="mega-menu-dropdown-button"
              className="inline-flex hover:bg-indigo-50 rounded-full p-2"
              type="button"
              onClick={() => setShowMenu(!showMenu)}
            >
              <ClipSVG />
            </button>
            {showMenu && (
              <div
                id="dropdown"
                className="absolute bottom-20 left-0 z-10 w-auto grid-cols-2 text-sm bg-white border border-gray-100 rounded-lg shadow-md"
              >
                <div className="p-4 pb-0 text-gray-900 md:pb-4 dark:text-white">
                  <ul
                    className="space-y-4"
                    aria-labelledby="mega-menu-dropdown-button"
                  >
                    {user?.claims?.type !== "S" && (
                      <li>
                        <button
                          id="addLink"
                          type="button"
                          className="group inline-flex items-center px-2 py-2 border border-transparent text-sm leading-5 font-medium rounded-full text-white bg-instant-teams-blue-Main hover:bg-white hover:fill-instant-teams-blue-Main hover:ring-2 hover:ring-instant-teams-blue-Main focus:outline-none"
                          onClick={() => {
                            handleNewMessageStart();
                            setShowMenu(!showMenu);
                            toggleLink();
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="1em"
                            viewBox="0 0 640 512"
                            className="fill-white group-hover:fill-instant-teams-blue-Main"
                          >
                            <path d="M579.8 267.7c56.5-56.5 56.5-148 0-204.5c-50-50-128.8-56.5-186.3-15.4l-1.6 1.1c-14.4 10.3-17.7 30.3-7.4 44.6s30.3 17.7 44.6 7.4l1.6-1.1c32.1-22.9 76-19.3 103.8 8.6c31.5 31.5 31.5 82.5 0 114L422.3 334.8c-31.5 31.5-82.5 31.5-114 0c-27.9-27.9-31.5-71.8-8.6-103.8l1.1-1.6c10.3-14.4 6.9-34.4-7.4-44.6s-34.4-6.9-44.6 7.4l-1.1 1.6C206.5 251.2 213 330 263 380c56.5 56.5 148 56.5 204.5 0L579.8 267.7zM60.2 244.3c-56.5 56.5-56.5 148 0 204.5c50 50 128.8 56.5 186.3 15.4l1.6-1.1c14.4-10.3 17.7-30.3 7.4-44.6s-30.3-17.7-44.6-7.4l-1.6 1.1c-32.1 22.9-76 19.3-103.8-8.6C74 372 74 321 105.5 289.5L217.7 177.2c31.5-31.5 82.5-31.5 114 0c27.9 27.9 31.5 71.8 8.6 103.9l-1.1 1.6c-10.3 14.4-6.9 34.4 7.4 44.6s34.4 6.9 44.6-7.4l1.1-1.6C433.5 260.8 427 182 377 132c-56.5-56.5-148-56.5-204.5 0L60.2 244.3z" />
                          </svg>
                        </button>
                      </li>
                    )}
                    <li>
                      <button
                        id="addImage"
                        type="button"
                        className="group inline-flex items-center px-2 py-2 border border-transparent text-sm leading-5 font-medium rounded-full text-white bg-instant-teams-blue-Main hover:bg-white hover:fill-instant-teams-blue-Main hover:ring-2 hover:ring-instant-teams-blue-Main focus:outline-none"
                        onClick={() => {
                          handleNewMessageStart();
                          setShowMenu(!showMenu);
                          toggleImage();
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          height="1em"
                          viewBox="0 0 640 512"
                          className="fill-white group-hover:fill-instant-teams-blue-Main"
                        >
                          <path d="M0 96C0 60.7 28.7 32 64 32H448c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96zM323.8 202.5c-4.5-6.6-11.9-10.5-19.8-10.5s-15.4 3.9-19.8 10.5l-87 127.6L170.7 297c-4.6-5.7-11.5-9-18.7-9s-14.2 3.3-18.7 9l-64 80c-5.8 7.2-6.9 17.1-2.9 25.4s12.4 13.6 21.6 13.6h96 32H424c8.9 0 17.1-4.9 21.2-12.8s3.6-17.4-1.4-24.7l-120-176zM112 192a48 48 0 1 0 0-96 48 48 0 1 0 0 96z" />
                        </svg>
                      </button>
                    </li>
                    <li className="pb-4">
                      <button
                        id="addFile"
                        type="button"
                        className="group text-center p-2 border border-transparent text-sm leading-5 font-medium rounded-full text-white bg-instant-teams-blue-Main hover:bg-white hover:fill-instant-teams-blue-Main hover:ring-2 hover:ring-instant-teams-blue-Main focus:outline-none"
                        onClick={() => {
                          handleNewMessageStart();
                          setShowMenu(!showMenu);
                          toggleFile();
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          height="1em"
                          viewBox="0 0 640 512"
                          className="fill-white group-hover:fill-instant-teams-blue-Main text-center"
                        >
                          <path d="M0 64C0 28.7 28.7 0 64 0H224V128c0 17.7 14.3 32 32 32H384V288H216c-13.3 0-24 10.7-24 24s10.7 24 24 24H384V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V64zM384 336V288H494.1l-39-39c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l80 80c9.4 9.4 9.4 24.6 0 33.9l-80 80c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l39-39H384zm0-208H256V0L384 128z" />
                        </svg>
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            )}
          </div>
        </div>
        {linkData !== null && (
          <p>
            Link Added:{" "}
            <a href={linkData.href} target={"_blank"}>
              {linkData?.title}
            </a>
          </p>
        )}
        {imageData !== null && (
          <div className="w-12 absolute right-24 top-6 ">
            <img src={imageData} alt={"New Image"} />
          </div>
        )}
        {fileData !== null && (
          <p className="text-xs">
            <i className="fas fa-file"></i>
            {" -> "}
            {fileNameData}
          </p>
        )}
      </div>
      <div>
        {/* <button
          className="inline-flex hover:bg-indigo-50 rounded-full p-2"
          type="button"
          onClick={handleSendClick}
        >
          Send
        </button> */}

        <button
          type="button"
          className="py-[21.5px] px-[80px] w-auto rounded-[70px] text-white bg-instant-teams-blue-Main border-0 hover:bg-instant-teams-blue-L1 focus:outline-none z-50 text-[16px]"
          onClick={handleSendClick}
        >
          Send
        </button>
      </div>
      {isShowingLink && (
        <DialogWrapper
          onClose={() => {
            toggleLink();
          }}
          title="LINK"
          displayCloseIcon={true}
        >
          <AddALink dataReturn={setLinkData} toggle={toggleLink} />
        </DialogWrapper>
      )}
      {isShowingImage && (
        <DialogWrapper
          onClose={() => {
            toggleImage();
          }}
          title="IMAGE"
          displayCloseIcon={true}
        >
          <AddAnImage
            dataReturn={setImageData}
            toggle={toggleImage}
            userId={senderId}
            chatId={chatId}
          />
        </DialogWrapper>
      )}
      {isShowingFile && (
        <DialogWrapper
          onClose={() => {
            toggleFile();
          }}
          title="FILE"
          displayCloseIcon={true}
        >
          <AddAFile_NewUI
            dataReturn={setFileData}
            toggle={toggleFile}
            userId={senderId}
            setName={setFileNameData}
            chatId={chatId}
          />
        </DialogWrapper>
      )}
    </div>
  );
};

export default MessageSend_NewUI;
