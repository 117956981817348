import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../providers/auth";
import { isEmpty } from "lodash";
import { createNewEntryForJobDetailsNewCollection } from "../../../data/jobDetailsNewCollection";
import { useAllActiveEmployers } from "../../../data/employer";
import { JobDetailsNewCollection } from "../../../dataTypes/JobDetailsNewCollection";

function CreateJobNewBasicForm({
  employerId,
  onDone = () => {},
  toggle,
  handleFormAlert,
}: {
  employerId?: string;
  onDone?: Function;
  toggle: Function;
  handleFormAlert: Function;
}) {
  const navigate = useNavigate();
  // const { user } = useAuth();
  const [empId, setEmpId] = useState("all");
  const { data: employers } = useAllActiveEmployers(empId);
  const [pending, setPending] = useState(false);
  const [employerValue, setEmployerValue] = useState(employerId);
  const [title, setTitle] = useState("");

  const onSubmit = async () => {
    if (!isEmpty(employerValue) && !isEmpty(title)) {
      setPending(true);

      const employer = employers.find((emp: any) => emp.id === employerValue);

      let companyFullName = null;
      let companyLogo = null;
      let companyHQ = null;

      if (employer?.displayName) companyFullName = employer?.displayName;
      if (employer?.logo) companyLogo = employer?.logo;
      if (employer?.address?.state && employer?.address?.country)
        companyHQ = `${employer?.address?.state}, ${employer?.address?.country}`;

      const jobDetailsNewCollectionData: JobDetailsNewCollection = {
        jobName: title,
        companyId: employerValue,
        creationDate: new Date(),
        jobState: "draft",
        startDateInclude:false,

        // giving null placeholder values to the fields which have not been filled yet
        applicationFlow: null,
        applyNowCounter: 0,
        clicksCounter: 0,
        closedFilledBy: "",
        closedReason: "",
        companyFullName: companyFullName,
        companyLogo: companyLogo,
        companyHQ: companyHQ,
        hoursPerWeek: null,
        instantTeamJobs: null,
        jobDescription: null,
        makeLiveDate: null,
        openings: null,
        payRate: null,
        solutionType: null,
        startDate: null,
        workSettings: null,
        workTerm: null,
        workType: null,
        // latestUpdate: null,
      };
      // console.log(jobDetailsNewCollectionData, '==========>jobDetailsNewCollectionData');
      // return;

      await createNewEntryForJobDetailsNewCollection(
        jobDetailsNewCollectionData,
      )
        .then((data) => {
          handleFormAlert("success");

          setTimeout(() => {
            onDone();
            toggle();
            navigate(`/jobs-new/${data}/${employerValue}/information`);
          }, 5000);
        })
        .catch(() => {
          handleFormAlert("error");
          setPending(false);
        });

      return;
    }
  };
  return (
    <>
      <div>
        {!employerId ? (
          <div className="mb-2">
            <select
              name="employer"
              className="w-full py-1 leading-8 bg-white rounded border border-gray-300 focus:border-blue-500 focus:ring-1"
              onBlur={(event) => setEmployerValue(event.target.value)}
              onChange={(event) => setEmployerValue(event.target.value)}
            >
              <option value="">Select Employer</option>
              {employers?.map((employer: any) => (
                <option key={employer.id} value={employer.id}>
                  {employer.displayName}
                </option>
              ))}
            </select>
          </div>
        ) : null}
        <div className="mb-2">
          <input
            className="py-1 px-3 w-full leading-8 bg-white rounded border border-gray-300 outline-none focus:border-blue-500 focus:ring-1"
            name="title"
            type="text"
            value={title}
            placeholder="Job Title"
            onChange={(event) => setTitle(event.target.value)}
          />
        </div>

        <button
          className="mt-4 py-2 px-4 w-full text-white bg-instant-teams-blue-Main rounded border-0 hover:bg-instant-teams-blue-L1 focus:outline-none"
          type="submit"
          onClick={onSubmit}
          disabled={pending}
        >
          {pending ? "..." : "Create New Job"}
        </button>
      </div>
    </>
  );
}

export default CreateJobNewBasicForm;
