import { useState, useEffect } from "react";
import { useAuth } from "../../../../../providers/auth";
import { useNavigate } from "react-router-dom";
import SeekerRegistrationStepOne from "./SeekerRegistrationStepOne";
import SeekerRegistrationStepTwo from "./SeekerRegistrationStepTwo";
import SeekerRegistrationStepThree from "./SeekerRegistrationStepThree";
import SeekerRegistrationStepFour from "./SeekerRegistrationStepFour";
import SeekerRegistrationStepFive from "./SeekerRegistrationStepFive";
import ForgotPasswordComponent from "./ForgotPasswordComponent";
import SeekerLogin from "./SeekerLogin";
import SeekerLoginAuth from "./SeekerLoginAuth";
import SeekerMFA from "./SeekerMFA";
import { remove } from "lodash";
import { cn } from "../../../../../util/cn";

function SeekerRegistrationIndexNew() {
  const auth = useAuth();
  const seekerEmail = localStorage.getItem("seekerEmail");
  const seekerMFAReset = localStorage.getItem("mfaReset");
  const navigate = useNavigate();
  const [isSignUP, setIsSignUP] = useState(false);
  const [forgotPasswordMode, setForgotPasswordMode] = useState(false);
  const totalSteps = 4;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeStepNew, setActiveStepNew] = useState(1);
  const [seekerInfo, setSeekerInfo] = useState();
  const [employerLogin, setEmloyerLogin] = useState(false);

  useEffect(() => {
    if (seekerEmail) {
      setSeekerInfo(JSON.parse(seekerEmail));
      setActiveStepNew(2);
      setIsSignUP(true);
      setIsModalOpen(true);
    }
  }, [seekerEmail]);

  useEffect(() => {
    if (seekerMFAReset === "true") {
      setActiveStepNew(2);
      setSeekerInfo("MFAD");
      setIsSignUP(false);
      setIsModalOpen(true);
    }
  }, [seekerMFAReset]);

  const handleForgotPasswordClick = () => {
    setIsModalOpen(true);
    setForgotPasswordMode(true);
  };

  const handleSignUpClick = () => {
    setIsModalOpen(true);
    setIsSignUP(true);
  };

  const handleSignInClick = (emp: boolean) => {
    setIsModalOpen(true);
    setIsSignUP(false);
    if (emp) setEmloyerLogin(true);
  };

  const closeModal = () => {
    localStorage.removeItem("seekerEmail");
    localStorage.removeItem("mfaReset");
    setIsModalOpen(false);
    setIsSignUP(false);
    window.location.reload();
  };

  const nextStep = () =>
    setActiveStepNew((prevState: any) => {
      const step = prevState + 1;
      return step;
    });

  const handleSeekerInfoDoneN = (data: any) => {
    setSeekerInfo(data);
    nextStep();
  };

  const handleLink = (link: any) => {
    window.open(link, "_blank");
  };

  const renderActiveStepNew = (activeStepNew: number) => {
    // alert(activeStepNew)
    if (forgotPasswordMode) {
      return <ForgotPasswordComponent />;
    }
    if (isSignUP) {
      switch (activeStepNew) {
        case 1:
          return <SeekerRegistrationStepOne onDone={handleSeekerInfoDoneN} />;
        case 2:
          return (
            <SeekerRegistrationStepTwo
              onDone={handleSeekerInfoDoneN}
              seekerInfo={seekerInfo}
            />
          );
        case 3:
          return (
            <SeekerRegistrationStepThree
              onDone={handleSeekerInfoDoneN}
              seekerInfo={seekerInfo}
            />
          );
        case 4:
          return (
            <SeekerRegistrationStepFour
              onDone={handleSeekerInfoDoneN}
              seekerInfo={seekerInfo}
            />
          );

        default:
          return (
            <SeekerRegistrationStepFive
              onDone={handleSeekerInfoDoneN}
              seekerInfo={seekerInfo}
            />
          );
      }
    } else {
      switch (activeStepNew) {
        case 1:
          return (
            <SeekerLogin
              onSingup={handleSignUpClick}
              onForgotPassword={handleForgotPasswordClick}
              onDone={handleSeekerInfoDoneN}
              employerLogin={employerLogin}
              handleSignInClick={handleSignInClick}
              seekerInfo={seekerInfo}
            />
          );
        case 2:
          return seekerInfo === "MFAD" ? (
            <SeekerMFA onDone={handleSeekerInfoDoneN} seekerInfo={seekerInfo} />
          ) : (
            <SeekerLoginAuth
              onDone={handleSeekerInfoDoneN}
              mfaResolver={seekerInfo}
            />
          );

        default:
          return <SeekerLogin onForgotPassword={handleForgotPasswordClick} />;
      }
    }
  };

  return (
    <>
      <div className="flex min-h-screen bg-white overflow-hidden">
        <div
          className="w-full h-screen bg-cover bg-center  relative"
          style={{
            backgroundImage: `url(${
              process.env.PUBLIC_URL + "/images/banner_bg.png"
            })`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        >
          <div className="w-full flex flex-col items-end h-full">
            <div className="w-[60%] xs:w-full flex flex-col items-center ">
              <div className="absolute top-1/4 flex flex-col  bg-white rounded-3xl shadow-lg p-10 w-[550px] xs:w-[300px] xs:self-center xs:p-6 xs:rounded-xl xs:top-1/3">
                <img
                  src="/images/InstantTeamsLogo_Main_1.png"
                  alt="instant teams logo"
                  className="mb-6 w-60 xs:w-[150px] xs:mb-3"
                />
                <h1 className="xs:text-sm text-2xl font-bold text-[#15415E] mb-6 xs:mb-3">
                  Shape the Future of <br />
                  Customer Experience with Us!
                </h1>
                <p className="xs:text-sm text-2xl mb-6 text-[#216478] xs:mb-3">
                  Your journey starts here.
                </p>

                {/* Login and Sign Up buttons */}
                <div className="flex gap-x-4 mb-4 xs:justify-between xs:w-full">
                  <button
                    className="px-6 py-3 text-lg font-semibold bg-white border border-[#15415E] rounded-full hover:bg-[#15415E] hover:text-[#ffffff] focus:outline-none w-[220px] h-[50px] xs:text-xs xs:px-1 xs:py-1 xs:w-[120px] xs:h-[30px]"
                    onClick={() => {
                      handleSignInClick(false);
                    }}
                  >
                    Login
                  </button>
                  <button
                    className="px-6 py-3 text-lg font-semibold text-white bg-[#15415E] rounded-full hover:bg-[#15415E] focus:outline-none w-[220px] h-[50px] xs:text-xs xs:px-1 xs:py-1 xs:w-[120px] xs:h-[30px]"
                    onClick={handleSignUpClick}
                  >
                    Sign Up
                  </button>
                </div>

                <p className="text-sm xs:text-xs xs:flex-wrap xs:whitespace-nowrap flex text-[#8AA0AE]">
                  If you're an employer looking to hire please{" "}
                  <span
                    onClick={() => {
                      handleSignInClick(true);
                    }}
                    className="text-[#15415E] cursor-pointer ml-1 mr-2 font-bold underline xs:ml-0"
                  >
                    log in here
                  </span>
                  {/* or
                  <p onClick={handleSignUpClick} className="text-[#15415E] ml-2 cursor-pointer font-bold underline">
                    Create an Account Here
                  </p> */}
                </p>
              </div>
            </div>
            <div className="w-[60%] flex flex-col h-full  justify-end items-center mb-12 xs:w-[100%]">
              <div>
                <p className="text-lg text-center text-white mb-4">
                  Download Our Mobile App
                </p>
                <div className="flex space-x-4">
                  <div>
                    <img
                      onClick={() =>
                        handleLink(
                          "https://play.google.com/store/apps/details?id=com.instantteams.athena&utm_source=landingPage&utm_campaign=launch&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1&pli=1",
                        )
                      }
                      src="/images/signupgoogle.png"
                      alt="Google Play Store"
                      className="w-36"
                    />
                  </div>
                  <div>
                    <img
                      onClick={() =>
                        handleLink(
                          "https://apps.apple.com/us/app/instant-teams-marketplace/id1666030415?ign-itscg=30200&ign-itsct=apps_box_badge",
                        )
                      }
                      src="/images/signupapple.png"
                      alt="App Store"
                      className="w-32"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {isModalOpen && (
          <div className="fixed inset-0 flex items-center justify-center z-50">
            <div
              className="fixed inset-0 bg-black opacity-50"
              onClick={closeModal}
            ></div>

            <div
              className={cn(
                "transform transition-transform duration-300 ease-in-out bg-white rounded-2xl cursor-pointer shadow-lg p-8 w-3/6 h-full overflow-y-auto no-scrollbar xs:w-[90%] xs:p-6",
                {
                  "translate-x-0 animate-slideIn": isModalOpen,
                  "translate-x-full": !isModalOpen,
                },
              )}
              style={{
                position: "absolute",
                right: 0,
                top: "50%",
                transform: "translateY(-50%)",
              }}
            >
              <div className="w-full flex justify-end  ">
                {" "}
                <div
                  onClick={closeModal}
                  className="px-[14px] py-2 bg-gray-300 rounded-full text-gray-600 "
                >
                  X
                </div>
              </div>
              {activeStepNew < 5 && (
                <div
                  className={`flex justify-between items-center  w-full max-w-3xl mx-auto p-6 bg-white ${
                    isSignUP ? "" : " invisible"
                  }`}
                >
                  {[
                    "Basic Information",
                    "Email Verification",
                    "Phone Verification",
                    "Military Affiliation",
                  ].map((label, index) => (
                    <div
                      key={index}
                      className="flex flex-col items-center relative w-full"
                    >
                      <div
                        className={`w-7 h-7 rounded-full border-2 flex items-center justify-center z-10 ${
                          activeStepNew >= index + 1
                            ? "bg-[#15415E] border-[#15415E] text-white"
                            : "bg-[#D4DFE3] border-[#D4DFE3] text-white"
                        }`}
                      >
                        <span
                          style={{ fontSize: "12px" }}
                          className="font-normal"
                        >
                          {index + 1}
                        </span>
                      </div>
                      {activeStepNew === index + 1 && (
                        <span className="text-xs -mb-4 uppercase text-[#15415E] font-semibold xs:mt-8 whitespace-nowrap xs:text-[10px] xs:font-bold xs:absolute">
                          {label}
                        </span>
                      )}

                      {/* Connecting line for each step */}
                      {index !== totalSteps - 1 && (
                        <div
                          className={`absolute top-1/2 transform -translate-y-1/2 h-1 ${
                            activeStepNew >= index + 2
                              ? "bg-[#15415E]"
                              : "bg-[#D4DFE3]"
                          }`}
                          style={{
                            width: "100%",
                            left: "50%",
                            right: "-50%",
                            zIndex: 1,
                          }}
                        ></div>
                      )}
                    </div>
                  ))}
                </div>
              )}

              {renderActiveStepNew(activeStepNew)}
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default SeekerRegistrationIndexNew;
