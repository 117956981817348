import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faTimes, faBars } from '@fortawesome/free-solid-svg-icons';
import { useAuth } from '../../../../../providers/auth';
import { useGlobalState } from '../../../../../providers/globalState/GlobalStateProvider';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import NotificationList_NewUI from '../../../../notifications/NotificationList_NewUI';
import ProfilePopup from './Profile';

const Header = () => {
    const { comapanyId = "" } = useParams();
    const location = useLocation();
    const currentPath = location.pathname;
    const navigate = useNavigate();
    const auth = useAuth();
    const { globalState, setGlobalState } = useGlobalState();
    const [searchTerm, setSearchTerm] = useState('');
    const [isSearching, setIsSearching] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    useEffect(() => {
        setSearchTerm("");
        setIsSearching(false);
        setGlobalState({ ...globalState, searchKeyWords: '' });
    }, [currentPath]);

    useEffect(() => {
        setSearchTerm(globalState.searchKeyWords);
    }, [globalState.searchKeyWords]);

    const handleSearch = () => {
        if (isSearching) {
            setSearchTerm("");
            setIsSearching(false);
            setGlobalState({ ...globalState, searchKeyWords: '' });
        } else if (searchTerm.trim()) {
            setIsSearching(true);
            setGlobalState({ ...globalState, searchKeyWords: searchTerm.trim() });
        }
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter' && searchTerm.trim()) {
            setIsSearching(true);
            setGlobalState({ ...globalState, searchKeyWords: searchTerm.trim() });
        }
    };

    const handleInputChange = (e) => {
        setSearchTerm(e.target.value);
        if (e.target.value.trim() === '') {
            setIsSearching(false);
            setGlobalState({ ...globalState, searchKeyWords: '' });
        }
    };

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    return (
        <div style={{
            backgroundImage: `url(${process.env.PUBLIC_URL + '/images/banner_bg-1.png'})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
        }}
            className="h-40 fixed w-full z-50 p-1 bg-cover">
            {!currentPath.startsWith("/social") && (
                <>
                    {/* Header Section */}
                    <div className="w-full h-24 flex justify-between items-center px-4 md:px-8">
                        <div onClick={() => navigate("/jobs")} className="cursor-pointer w-48">
                            <img src="/images/InstantTeamsLogo_Main_1.png" alt="Instant Teams Logo" />
                        </div>
                        {/* Hamburger Icon */}
                        <div className="block lg:hidden  xl:hidden 2xl:hidden">
                            <FontAwesomeIcon
                                icon={faBars}
                                className="text-[#1E596D] text-2xl cursor-pointer"
                                onClick={toggleMenu}
                            />
                        </div>
                        {/* Desktop Navigation */}
                        <div className="hidden lg:flex xl:flex 2xl:flex items-center space-x-4">
                            <div
                                onClick={() => navigate("/jobs")}
                                className={`px-4 py-2 cursor-pointer ${currentPath.startsWith("/jobs") ? "border-b-2 border-[#4357a7]" : ""}`}
                            >
                                <h1>Jobs</h1>
                            </div>
                            <div
                                onClick={() => { navigate("/companies"); setGlobalState({ ...globalState, searchKeyWords: '' }); }}
                                className={`px-4 py-2 cursor-pointer ${currentPath.startsWith("/companies") ? "border-b-2 border-[#4357a7]" : ""}`}
                            >
                                <h1>Companies</h1>
                            </div>
                        </div>
                        {/* Actions */}
                        <div className="hidden lg:flex  xl:flex 2xl:flex items-center space-x-4">
                            <div onClick={() => navigate("/faq")} className="px-4">
                                <span className="text-lg font-bold cursor-pointer text-[#1E596D] border-[#1E596D] flex h-6 w-6 items-center justify-center rounded-full border-2 bg-white shadow-lg">
                                    i
                                </span>
                            </div>
                            <NotificationList_NewUI userId={auth?.user?.id} />
                            <div onClick={() => navigate(`/messages/${auth.user.id}`)} className="px-3">
                                <i className="far fa-comment-alt text-[22px] cursor-pointer text-[#1E596D]"></i>
                            </div>
                            <ProfilePopup />
                        </div>
                    </div>

                    {/* Mobile Menu */}
                    {isMenuOpen && (
                        <div className="lg:hidden  xl:hidden 2xl:hidden bg-white shadow-md rounded-md  absolute w-full z-40">
                            <ul className="flex flex-col space-y-2 p-4">
                                <li
                                    onClick={() => { navigate("/jobs"); toggleMenu(); }}
                                    className={`cursor-pointer ${currentPath.startsWith("/jobs") ? "text-instant-teams-blue-Main" : "text-gray-600"}`}
                                >
                                    Jobs
                                </li>
                                <li
                                    onClick={() => { navigate("/companies"); setGlobalState({ ...globalState, searchKeyWords: '' }); toggleMenu(); }}
                                    className={`cursor-pointer ${currentPath.startsWith("/companies") ? "text-instant-teams-blue-Main" : "text-instant-teams-blue-Main"}`}
                                >
                                    Companies
                                </li>
                                <li
                                    onClick={() => { navigate("/faq"); toggleMenu(); }}
                                    className="cursor-pointer text-instant-teams-blue-Main"
                                >
                                    FAQ
                                </li>
                                <li
                                    onClick={() => { navigate(`/notifications/${auth.user.id}`); toggleMenu(); }}
                                    className="cursor-pointer text-instant-teams-blue-Main"
                                >
                                   Notifications
                                </li>
                                
                                <li
                                    onClick={() => { navigate(`/messages/${auth.user.id}`); toggleMenu(); }}
                                    className="cursor-pointer text-instant-teams-blue-Main"
                                >
                                   Support
                                </li>
                                <li
                                    onClick={() => { navigate(`settings/newprofile`); toggleMenu(); }}
                                    className="cursor-pointer text-instant-teams-blue-Main"
                                >
                                    Profile
                                </li>
                                <li
                                    onClick={() => {  auth.signout(); }}
                                    className="cursor-pointer text-semibold text-instant-teams-blue-Main"
                                >
                                  Log out
                                </li>
                            </ul>
                        </div>
                    )}

                    {/* Search Bar */}
                    <div className="w-full pb-3 flex justify-center">
                        <div className="relative w-[40%] xs:w-[95%] md:w-1/2">
                            <input
                                type="text"
                                value={searchTerm}
                                onChange={handleInputChange}
                                onKeyPress={handleKeyPress}
                                placeholder={currentPath.startsWith("/companies") ? "Search by Company Name" : 'Search by Job Title or Company Name'}
                                className="w-full pl-4 pr-10 py-3 text-gray-600 bg-white rounded-full text-sm shadow-md focus:outline-none focus:ring-2 focus:ring-blue-300"
                            />
                            <div onClick={handleSearch} className="absolute inset-y-0 right-4 flex items-center cursor-pointer">
                                <FontAwesomeIcon
                                    icon={(isSearching && searchTerm) || (searchTerm && comapanyId) ? faTimes : faSearch}
                                    className="text-gray-400 w-4 h-4"
                                />
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default Header;
