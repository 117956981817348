import { customDataRouteObject } from "../dataTypes/ReactRouter";
import GeneralInfoPage from "../pages/general-info/generailInfo";

import MessagesPage from "../pages/messages/MessagesPage";
import NotificationsPage from "../pages/notifications/NotificationsPage";
import SettingsPage from "../pages/users/settings";

import { ErrorBoundary } from "react-error-boundary";
import Fallback from "../components/fallback/Fallback";

export const sharedChildren: customDataRouteObject[] = [
  {
    id: "FAQ",
    name: "FAQ",
    path: "/faq",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <GeneralInfoPage />
      </ErrorBoundary>
    ),
  },
  {
    id: "Message",
    name: "Message",
    path: "/messages/:userId",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <MessagesPage />
      </ErrorBoundary>
    ),
  },
  {
    id: "My Notifications",
    name: "My Notifications",
    path: "/notifications/:userId",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <NotificationsPage />
      </ErrorBoundary>
    ),
  },
  {
    id: "Settings",
    name: "Settings",
    path: "/settings/:section",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <SettingsPage />
      </ErrorBoundary>
    ),
  },
];
