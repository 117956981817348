import { useEffect, useState } from "react";
import { gql, useQuery } from "@apollo/client";
import { wordpressClient } from "../../external/wordpress/wordpressClient";
import { sanitizeMarkup } from "../../util/sanitize/sanitize-html";
import "../../styles/wordpress.css";

const GeneralInfoCategoryPost_NewUI = ({ id }) => {
    const [nodeData, setNodeData] = useState();
    const [showContent, setShowContent] = useState(false);

    const node = gql`
    query getPostContent($id: ID!) {
      post(id: $id, idType: ID) {
        id
        content(format: RENDERED)
        title
      }
    }
  `;

    const { loading, error, data } = useQuery(node, {
        variables: { id },
        client: wordpressClient,
    });

    useEffect(() => {
        setNodeData(data?.post);
        console.log(data?.post);
    }, [data]);

    const { content, title } = nodeData || {};

    return (
        <div
            key={data?.post?.id}
            className={`flex flex-col justify-center cursor-pointer py-4 border-t-[1px] border-instant-teams-teal-L4 xs:w-full  text-lg ${showContent ? "h-auto py-5" : "h-20"
                }`}
            onClick={() => setShowContent(!showContent)}
        >
            <div className="flex justify-between items-center">
                <span className=" text-instant-teams-blue-Main text-lg">{title}</span>
                <div className=" text-xl border-2 border-instant-teams-teal-Main px-[2px] h-5 w-5 flex justify-center items-center text-instant-teams-teal-Main">{ !showContent?"+":"-"}</div>
            </div>
            {showContent && (
                <div
                    className="wordpress ml-[-18px] xs:ml-[-5px] text-gray-500 py-1 text-[15px]"
                    dangerouslySetInnerHTML={sanitizeMarkup(content)}
                ></div>
            )}
        </div>
    );
};

export default GeneralInfoCategoryPost_NewUI;
